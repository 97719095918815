const youtube = (value) => {
  if (value) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
    const match = value.match(regExp)
    return Boolean(match && match[2].length == 11)
  } else {
    return false
  }
}

export default youtube
