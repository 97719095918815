import Vue from 'vue/dist/vue.esm'
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { i18n } from './i18n'
import url from './validations/url'
import youtube from './validations/youtube'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('url', (value) => url(value))
extend('youtube', (value) => youtube(value))

configure({
  classes: {
    invalid: 'is-invalid'
  },

  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`form.fields.${field}`)

    return i18n.t(`form.validation.messages.${values._rule_}`, values)
  }
})
