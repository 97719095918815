import Vue from 'vue/dist/vue.esm'
import VueAxios from 'vue-axios'
import VueTailwind from 'vue-tailwind'
import { TInput, TDatepicker } from 'vue-tailwind/dist/components'
import Portuguese from 'vue-tailwind/dist/l10n/pt'
import VueSimpleAlert from "vue-simple-alert";

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import axios from 'helpers/axios'
import { i18n } from 'plugins/i18n'
import 'plugins/vee-validate'

import '../stylesheets/buefy.scss'
import '../stylesheets/application.scss'

Rails.start()
ActiveStorage.start()


const tailwindConfig = {
  't-input': {
    component: TInput,
    props: {
      classes: 'border-2 block w-full rounded text-gray-800'
    }
  },
  't-datepicker': {
    component: TDatepicker,
    props: {
      locale: Portuguese
    }
  }
}

Vue.use(VueAxios, axios)
Vue.use(VueTailwind, tailwindConfig)
Vue.use(VueSimpleAlert);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementsByClassName('cms-admin').length > 0) {
    const siteVm = new Vue({
      el: '.cms-admin',
      i18n,
      components: {
        'AdminAdminList': () => import('components/admin/admins/List'),
        'AdminArticleForm': () => import('components/admin/articles/Form'),
        'AdminContentList': () => import('components/admin/contents/List'),
        'AdminFrequentlyAskedQuestionList': () => import('components/admin/frequentlyAskedQuestions/List'),
        'AdminNewsForm': () => import('components/admin/news/Form'),
        'AdminPageForm': () => import('components/admin/pages/Form'),
        'AdminPageSectionDetails': () => import('components/admin/pages/SectionDetails'),
        'AdminPublicationForm': () => import('components/admin/publications/Form'),
        'AdminTagList': () => import('components/admin/tags/List'),
        'AdminTeamPersonFieldMedia': () => import('components/admin/team/people/FieldMedia'),
        'AdminTeamPersonList': () => import('components/admin/team/people/List'),
        'AdminVideoForm': () => import('components/admin/video/Form'),
        'AuthorList': () => import('components/admin/authors/List'),
        'InputCkeditorMinimal': () => import('components/form/InputCkeditorMinimal'),
        'LateralMenu': () => import('components/layout/LateralMenu'),
        'LateralMenuItem': () => import('components/layout/LateralMenuItem'),
        'UserMenu': () => import('components/header/UserMenu')
      }
    })
  }
})
